<template>
  <v-container class="wrapper mt-4">
    <div class="flipper" :class="flipperClass == true ? 'flip-class' : null">
      <div class="front">
        <v-form ref="signInForm" v-model="signInForm">
          <v-card elevation="5" color="cardBackground">
            <v-card-title class="text-h4 gotham-font">Sign In</v-card-title>
            <v-card-subtitle class="text-body-1 gotham-font">Customer Portal</v-card-subtitle>
            <v-card-text>
              <v-text-field
                required
                autofocus
                ref="email"
                name="email"
                label="EMAIL"
                v-model="email"
                :rules="emailRules"
                :disabled="loading"
              ></v-text-field>

              <v-text-field
                required
                ref="password"
                name="password"
                label="PASSWORD"
                class="mt-4"
                v-model="password"
                :type="value ? 'password' : 'text'"
                :rules="passwordRules"
                :disabled="loading"
                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (value = !value)"
              ></v-text-field>

              <v-row class="mb-12">
                <v-col cols="6"></v-col>
                <v-col cols="6" class="text-right">
                  <v-btn
                    block
                    color="success"
                    class="px-8"
                    type="submit"
                    :loading="loading"
                    :disabled="!signInForm"
                    @click.native.prevent="handleSignInClick()"
                  >
                    LOGIN
                  </v-btn>
                </v-col>
              </v-row>

              <div class="mb-8">
                <!-- <v-btn text class=" flip-btn" @click="flipCard()">Forgot Password</v-btn> -->
                <v-btn text target="_blank" :href="passwordUrl" @click.stop class="mainTextColor--text"> Forgot Password </v-btn>
              </div>
              <div>
                <v-btn text class="" :to="{ name: 'CreateAccount' }">
                  <span class="grey--text body-2 mr-2 text-lowercase">Not a member, click to </span>Sign Up Now
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </div>
      <div class="back">
        <v-form ref="resetPasswordForm" v-model="resetPasswordForm">
          <v-card tile flat class="gotham-font">
            <v-card-title class="text-h5 mb-4">Reset Your Password</v-card-title>
            <v-card-subtitle class="text-body-1">
              Use the form below to send an email to <span class="font-weight-black">register@thetitlegirl.com</span> with the subject
              <span class="font-weight-black">reset password</span> and a link will be emailed to you
            </v-card-subtitle>
            <v-card-text>
              <v-text-field
                required
                name="email"
                label="Email Address"
                autocomplete="off"
                v-model="resetEmail"
                :rules="emailRules"
                :disabled="loading"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <div class="d-flex" style="width: 100%">
                <v-btn text @click="flipCard()"><v-icon>mdi-arrow-left</v-icon> back</v-btn>
                <v-btn
                  type="submit"
                  color="success"
                  class="ml-auto"
                  :loading="loading"
                  :disabled="!resetPasswordForm"
                  target="_blank"
                  @click.stop
                  @click.native.prevent="sendResetPassword()"
                >
                  Reset Password
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import VueRouter from '../../router';
import CryptoService from '../../services/crypto.js';

export default {
  name: 'SignIn',
  props: {
    showNeedAuthMsg: {
      type: Boolean,
      required: false,
      defalut: false,
    },
    errorMsg: {
      type: String,
      required: false,
      default: null,
    },
  },
  mounted() {},
  created() {
    if (this.showNeedAuthMsg) {
      this.showErrorSnack('Please sign in to proceed');
    }
    if (this.errorMsg) {
      this.showErrorSnack(this.errorMsg);
    }

    this.passwordUrl = this.$store.getters['signIn/forgotPasswordUrl'];
  },
  components: {
    // Subscribe: () => import('@/components/home/Subscribe.vue'),
  },
  data: () => ({
    flipperClass: false,
    value: true,
    signInForm: false,
    resetPasswordForm: false,
    email: '',
    password: '',
    resetEmail: '',
    loading: false,
    passwordUrl: null,
    emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    passwordRules: [
      (v) => !!v || 'Password is required',
      (v) => (v && v.length >= 5) || 'Password must have at least 5 characters',
      (v) => /^[0-9a-zA-Z!@#$%^&*()?-_]*$/.test(v) || 'Only numbers, letters and special chars can be used',
    ],
  }),
  computed: {},
  watch: {},
  methods: {
    showErrorSnack(text) {
      this.$store.commit('snackmsg/show', { text, color: 'error' });
    },
    flipCard: function () {
      this.$refs.signInForm.reset();
      this.$refs.resetPasswordForm.reset();
      this.flipperClass = !this.flipperClass;
    },
    sendResetPassword() {
      // this.showErrorSnack('TODO: send "' + this.resetEmail + '" to api endpoint');
      window.location.href =
        'mailto:register@thetitlegirl.com?subject=reset password&body=Hello,%0%APlease reset the password for the user: ' + this.resetEmail;
      this.$refs.resetPasswordForm.reset();
      this.flipperClass = !this.flipperClass;
    },
    handleSignInClick() {
      this.loading = true;
      const encryptedPwd = new CryptoService().getEncryptedPwd(this.password);
      console.log("sign in click");
      const signInObj = {
        Username: this.email,
        Password: encryptedPwd,
      };

      this.$store
        .dispatch('app/signIn', signInObj)
        .then(response => {
          console.log("tech router")
          VueRouter.push({
            name: 'app.dashboard',
            params: { successMsg: `Welcome back ${this.email}` },
          });
        })
        .catch((error) => {
          if (error=="TypeError: Cannot read properties of undefined (reading 'status')")
            error="Your username or password has been entered incorrect, please try again. If you have forgotten your password, you can click on the Reset Password link to create a new password.";

          this.loading = false;
          this.showErrorSnack(error);
          this.password = '';
          this.$nextTick(() => this.$refs['password'].$refs.input.focus());
        });
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 400px;
  height: 100%;
}
.text-shadow {
  text-shadow: 2px 2px black;
}

.flip-btn {
  text-align: left;
}
.flip-btn:hover {
  cursor: pointer;
  /* text-decoration: underline; */
}
.flip-class {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  height: 100%;
}

/* flip speed goes here */
.flipper {
  position: relative;
  transition: 0.6s;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

/* hide back of pane during swap */
.front,
.back {
  position: absolute;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  width: inherit;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
}
</style>
